@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: $basic-background;
  height: 100%;
}

html {
  height: 100%;
}


@media screen and (max-width: 968px) {
  .p-contextmenu {
    width: 8.5rem !important;
    font-size: 12px
  }
  .p-contextmenu .p-submenu-list {
    padding: 0;
    min-width: 50% !important;
  }
  .p-contextmenu .p-menuitem-link {
    padding: 5px;
  }
}

@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

$font-family: var(--font-family);
$box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.3);
$container-length: 1199px;
$checkbox-label-color: #777;

$basic-light-color: var(--surface-400);
$basic-color: #f0f0f1;
$header-basic-color: var(--blue-700);
$basic-blue: var(--primary-color);
$hovered-row-background: var(--blue-100);
$left-bar-color: var(--blue-600);
$basic-white: var(--background-color);
$basic-background: var(--background-color);
$selected-button: var(--selected-button);
$secondary-blue: var(--blue-500);
$online-green: var(--green-500);
$button-shadow-color: rgb(231, 234, 238);
$light-grey: var(--gray-200);
$text-color: var(--surface-900);
$dark-grey: var(--gray-700);
$sidebar-color: var(--surface-700);
$login-register-pages: linear-gradient(90deg, var(--indigo-900) 0%, var(--indigo-600) 57%, var(--indigo-300) 100%);
$left-bar-selected-item-color: var(--left-bar-selected-item-color);

.employee-add-btn {
  background-color: $basic-blue;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .add-contact-icon {
    color: $basic-white;
    font-size: 15px;
  }
}

.input-label {
  color: $text-color;
  font-family: var(--font-family);
}

.input-box {
  width: 100%;
  flex: 1;
}

.copy-btn {
  background-color: $basic-blue;
  font-size: 16px;
  width: 40px !important;
  height: 35px;
}

.copied-btn {
  color: var(--blue-100);
  font-size: 16px;
  width: 72px !important;
  height: 35px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.readonly-input {
  color: #777;
  font-size: 18px;
  border: none;
}

.table-button {
  cursor: pointer;
  padding: 7px;
  height: 60px;
  border-radius: 7px;
  min-width: 80px;

  &:hover {
    background: rgba(0, 119, 211, 0.1);
  }
}

.table__action-container {
  min-width: fit-content;
  cursor: pointer;
  margin: 7px;
  padding: 10px;
  height: 50px;
  border-radius: 7px;

  &.readonly {
    filter: opacity(0.5);
    cursor: unset;
  }

  &:not(.readonly) {
    &:hover {
      background: rgba(0, 119, 211, 0.1);
    }
  }
}

.table__action-button {
  font-size: 16px;
}

.navigation-img {
  font-size: 20px;
  color: $left-bar-color;
}

.header-buttons-container {
  background-color: $basic-background;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}

.extraLargeIcons {
  width: 200px;
  height: 200px;
  font-size: 20px;
}

.largeIcons {
  width: 150px;
  height: 150px;
  font-size: 18px;
}

.mediumIcons {
  width: 120px;
  height: 120px;
  font-size: 16px;
}

.smallIcons {
  width: 100px;
  height: 100px;
  font-size: 14px;
}

.invalid-label {
  color: #e24c4c;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.table-height  {
  height: calc(100% - 140px);
}

.popup-form {
  width: 100%;
}

.popup-form__form {
  display: flex;
  gap: 20px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.popup-form__body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 30px;
}

.folder-icon, .file-icon {
  font-size: 16px;
  font-weight: bold;
  color: $basic-blue;
}

:host ::ng-deep .p-splitter {
  border: none;
}

:host ::ng-deep .p-treetable .p-treetable-thead > tr > th {
  padding: 0 0.79rem;
  border-bottom: 2.5px solid #dee2e6;
  border-top: 0;
  height: 50px;
}

:host ::ng-deep .p-treetable .p-treetable-tbody > tr > td {
  padding: 0.04rem;
}

.no-user-selection {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.right-panel__content, .left-panel__content {
  width: 100%;
  height: 100%;
}

.tools {
  user-select: none;
}

.builder-wrapper {
  display: flex;
  justify-content: flex-start;
}

.builder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .builder__field {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .builder__field-checkbox {
      display: flex;
      gap: 10px;

      label {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  .builder__button {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .builder__submit-button {
      width: 120px;
    }
  }

  .builder__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 30px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 30px;
  height: 100%
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.filter-selected {
  background: rgba(0, 119, 211, 0.2);
}

.filter-txt {
  font-size: 16px;
}

.shell-header-button {
  cursor: pointer;
}

.app-drawer {
  &__content {
    padding: 20px;
  }

  &__title {
    font-size: 5rem;
    margin-bottom: 10px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-header {
      font-weight: bold;
      padding: 8px;
      text-align: left;
    }

    &-cell {
      padding: 8px;
      border-bottom: 1px solid #ccc;
    }
  }
}

.remaining-time {
  align-self: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.progress-container {
  position: relative;
  display: inline-block;
}

.tool-txt {
  font-size: 16px;
}

.items-count-icon {
  font-size: 13px;
  font-weight: bold;
  color: $basic-blue;
}

@media screen and (max-width: 768px) {
  .table__action-container {
    margin: 5px;
    padding: 5px;
    height: 30px;
    .table__action-button,  .navigation-img {
      font-size: 14px
    }
  }
  .filter-txt, .table__action-button {
    font-size: 12px !important;
  }
}
